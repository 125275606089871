
import { Component, Vue } from "vue-property-decorator";

import ScoringComponent from "./Scoring.vue";

@Component({
  components: {
    ScoringComponent,
  },
})
export default class ScoringModalComponent extends Vue {}
